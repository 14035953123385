<template>
  <section class="message task-created">
    <section>
      <hub-icon name="account-voice" size="xlg" />
      <div>
        <div>
          <em><label>Sender</label>: {{ createdBy }}</em>
        </div>
        <div>{{ message }}</div>
      </div>
      <div class="metadata">
        <div>
          {{ new Date(createdAt).toLocaleString() }}
        </div>
      </div>
      <div></div>
    </section>
  </section>
</template>

<script>
import Icon from './../../common/Icon';

export default {
  components: {
    'hub-icon': Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    createdBy: {
      type: String,
      required: true
    },
    createdAt: {
      type: [Date, String],
      required: true
    },
    metadata: {
      type: Object,
      default: () => null
    }
  }
};
</script>

<style lang="scss" scoped>
.message.task-created {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  width: 100%;
  // background-color: var(--theme-surface);
  border-radius: 3px;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  grid-gap: 0.25rem;

  label {
    font-weight: 600;
  }

  em {
    font-size: 0.65rem;
    font-style: italic;
    color: var(--theme-on-background-accent);
  }

  > section {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr) max-content max-content;
    grid-template-rows: max-content;
    grid-gap: 0.5rem;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }

  .metadata {
    text-align: right;
    font-size: 0.65rem;
  }
}
</style>
